window.setTimeout(function () {
    const modalCookies = document.getElementById('modal-cookies'),
        modalClose = document.getElementById('modal-cookies-close')
    if (window.localStorage) {
        modalCookies.classList.add('active')

        if (modalClose) {
            var nextPopup = localStorage.getItem(modalCookies)

            if (nextPopup > new Date()) {
                modalCookies.classList.remove('active')
                return
            }

            var expires = new Date()
            expires = expires.setHours(expires.getHours() + 24)

            localStorage.setItem(modalCookies, expires)

            modalClose.addEventListener('click', () => {
                modalCookies.classList.remove('active')
            })
        }
    }
}, 2000)


